<template>
  <div style="height: 100vh" class="my-5">
    <div class="columns is-centered is-vcentered">
      <div class="column is-4-desktop is-6-tablet is-full-mobile">
        <div class="card">
          <div class="card-header" style="background: linear-gradient(89.31deg, #211895 1.38%, #E64BFF 97.6%);">
            <div class="card-header-title is-flex is-justify-content-space-between is-align-items-center">
              <img width="150" :src="`/logos/${site}/logo.svg`" />
              <img width="150" :src="`/logos/${site}/papara.svg`" />
            </div>
          </div>
          <div class="card-content is-centered" v-if="tab === 0">
            <p class="mb-4 p-3 has-text-centered has-background-danger has-text-white">{{ errMsg }}</p>
          </div>
          <section class="section is-main-section" v-if="tab === 1">
            <form @submit.prevent="save">
              <b-field label="Ad & Soyad" message="Papara hesabınızdaki ad soyad ile aynı olmalıdır">
                <b-input v-model="form.name" type="is-small" />
              </b-field>
              <b-field label="Yatırım tutarı">
                <b-input v-model="form.amount" type="is-small" />
              </b-field>
              <div class="is-flex is-full is-justify-content-end">
                <b-button class="mr-3" type="is-danger" size="is-medium" @click="windowClose">Kapat</b-button>
                <b-button :disabled="!isValid" type="is-primary" expanded size="is-medium" native-type="submit">Devam
                  et</b-button>
              </div>
            </form>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import {
  IntegrationService,
  TransactionService,
  UserService,
} from "@/services";

export default {
  name: "PaparaPayIframe",
  components: { CardComponent },
  data() {
    return {
      loading: false,
      error: false,
      errMsg: "",
      tab: 0,
      form: {
        appKey: this.$route.query.appKey,
        appSecret: this.$route.query.appSecret,
        username: this.$route.query.username,
        name: this.$route.query.name,
        amount: this.$route.query.amount,
        userId: this.$route.query.userId,
        jwt: this.$route.query.jwt,
        processId: this.$route.query.processId,
        successRedirectUrl: this.$route.query.successRedirectUrl,
        failRedirectUrl: this.$route.query.failRedirectUrl
      },
    };
  },
  computed: {
    isValid() {
      return (
        this.form.appKey &&
        this.form.appSecret &&
        this.form.userId &&
        this.form.username &&
        this.form.name &&
        this.form.amount >= 0,
        this.form.successRedirectUrl,
        this.form.failRedirectUrl
      );
    },
  },
  mounted() {
    const html = document.getElementsByTagName("html")[0];
    html.classList = [];
    this.init();
  },
  methods: {
    init() {
      UserService.providerCheck(this.form)
        .then(() => (this.tab = 1))
        .catch(() => (this.error = true));
    },
    async save() {
      const request = {
        username: this.form.username,
        name: this.form.name,
        amount: this.form.amount,
        userId: this.form.userId,
        jwt: this.form.jwt,
        processId: this.form.processId,
        successRedirectUrl: this.form.successRedirectUrl,
        failRedirectUrl: this.form.failRedirectUrl
      };
      const { data } = await TransactionService.hashCreate(
        request,
        this.form.appSecret
      );
      IntegrationService.paparaDeposit(
        request,
        data,
        this.form.appKey
      )
        .then((res) => {
          window.location.href = res.data.depositUrl;
        })
        .catch((err) => {
          this.tab = 0;
          this.errMsg = err.message;
        });
    },
  },
};
</script>
